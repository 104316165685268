import React from 'react'
import dayjs from 'dayjs'
import classNames from 'classnames'
import EbooksIcon from '../images/resources/ebooks-icon.inline.svg'
import CustomerStoriesIcon from '../images/resources/customer-stories.inline.svg'
import WhitePapersIcon from '../images/resources/white-papers.inline.svg'

export type ResourceListItemProps = {
  title: string
  description: string
  category?: {
    name: string
    slug: string
  }
  slug: string
  date: string
}

export default function ResourceListItem(
  props: ResourceListItemProps
): JSX.Element | null {
  const { title, description, category, slug, date } = props

  if (!category) {
    return null
  }

  const href = `/resources/${category.slug}/${slug}`

  function getIcon() {
    if (!category) return null
    if (category.slug === 'ebooks') {
      return <EbooksIcon />
    }
    if (category.slug === 'customer-stories') {
      return <CustomerStoriesIcon />
    }
    if (category.slug === 'white-papers') {
      return <WhitePapersIcon />
    }
    return null
  }

  return (
    <article className="flex flex-col gap-6 items-center justify-between p-6 border border-neutral-200 rounded-lg lg:flex-row">
      <div>
        <div className="group relative">
          <h3 className="mt-0">
            <a
              href={href}
              className="text-black text-xl font-semibold group-hover:text-gray-600 leading-6"
            >
              <span className="absolute inset-0" />
              <div className="flex gap-2 flex-col xl:flex-row">
                <span aria-hidden>{getIcon()}</span>
                <span className="flex-1">{title}</span>
              </div>
            </a>
          </h3>
          <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
            {description}
          </p>
        </div>
        <div className="flex items-center gap-4 text-sm mt-4 flex-wrap">
          <div className="inline-flex gap-2">
            <span>Published</span>
            <time dateTime={date} className="font-bold">
              {dayjs(date).format('MMM D, YYYY')}
            </time>
          </div>
          <span
            className={classNames(
              'px-2 py-1 rounded-full border text-xs font-semibold uppercase leading-3 text-center',
              {
                'border-cyan-500': category.slug === 'ebooks',
                'border-orange-400': category.slug === 'customer-stories',
                'border-fuchsia-900': category.slug === 'white-papers',
              }
            )}
          >
            {category.name}
          </span>
        </div>
      </div>
      <a className="btn-secondary border w-full lg:w-auto" href={href}>
        Learn More
      </a>
    </article>
  )
}
