import React from 'react'
import classNames from 'classnames'
import { useLocation } from '@reach/router'

import Layout from '../components/layout'
import Seo from '../components/Seo'
import StructuredData from '../components/structured-data'
import ResourceListItem from '../components/ResourceListItem'
import { Link, graphql } from 'gatsby'
import Pagination from '../components/pagination/pagination'

type ResourcesTemplateProps = {
  location: Record<string, unknown>
  pageContext: {
    homeUrl: string
  }
  data: {
    wpPage: {
      uri: string
      seo: Record<string, unknown>
    }
    allWpResource: {
      nodes: Array<{
        id: string
        slug: string
        title: string
        link: string
        date: string
        excerpt: string
        resourcesCategories: {
          nodes: Array<{
            name: string
            link: string
            slug: string
            id: string
          }>
        }
        commentCount: number
      }>
      pageInfo: {
        hasNextPage: boolean
        hasPreviousPage: boolean
        currentPage: number
        pageCount: number
      }
    }
    allWpResourcesCategory: {
      nodes: Array<{
        id: string
        name: string
        slug: string
        link: string
      }>
    }
  }
}

export default function ResourcesTemplate(
  props: ResourcesTemplateProps
): JSX.Element {
  const { data, pageContext } = props
  const { homeUrl } = pageContext

  const location = useLocation()
  const currentPathname = location.pathname

  const {
    allWpResource: { pageInfo, nodes: resources },
    allWpResourcesCategory: { nodes: dbCategories },
  } = data
  const { hasNextPage, hasPreviousPage, currentPage, pageCount } = pageInfo

  const categories = [
    { name: 'All', link: '/resources', slug: '', id: 'all', isActive: false },
    ...dbCategories.map(category => ({
      ...category,
      link: `/resources/${category.slug}`,
      isActive: currentPathname.startsWith(`/resources/${category.slug}`),
    })),
  ].map((category, _, allCategories) => {
    if (category.slug !== '') return category
    const anyCategoryIsActive = allCategories.some(
      category => category.isActive
    )
    return {
      ...category,
      isActive: !anyCategoryIsActive,
    }
  })

  return (
    <Layout>
      <Seo
        post={{
          seo: {
            title: 'Web Hosting Resources | Liquid Web',
            metaDesc:
              'A collection of ebooks, guides, white papers, and other hosting resources for growing businesses and their designers, developers, and agencies.',
          },
        }}
      />
      <StructuredData pageType="webPage" />
      <div className="container">
        <div className="py-10 lg:py-12 px-2 sm:px-4 md:px-4 lg:px-32 text-center">
          <h1 className="text-[32px] mb-3 mt-0">Hosting Resources from Liquid Web</h1>
          <p>
            Ebooks, guides, Customer Stories, white papers and more to help you
            grow.
          </p>
          <div className="flex justify-center">
            <div className="flex flex-wrap justify-center mt-4 z-10 gap-4">
              {categories.map(tab => {
                const { slug, name, link, isActive } = tab
                return (
                  <Link
                    to={link}
                    key={slug}
                    className={classNames(
                      'px-4 py-2 rounded-full border justify-center items-center gap-2.5 inline-flex text-sm font-semibold leading-none whitespace-nowrap hover:opacity-80',
                      {
                        'border-neutral-950': !slug,
                        'border-neutral-950 bg-neutral-950': !slug && isActive,
                        'border-cyan-500': slug === 'ebooks',
                        'border-cyan-500 bg-cyan-500':
                          slug === 'ebooks' && isActive,
                        'border-orange-500': slug === 'customer-stories',
                        'border-orange-400 bg-orange-400':
                          slug === 'customer-stories' && isActive,
                        'border-fuchsia-500': slug === 'white-papers',
                        'border-fuchsia-900 bg-fuchsia-900':
                          slug === 'white-papers' && isActive,
                        'text-black hover:text-black focus:text-black':
                          !isActive,
                        'text-white hover:text-white focus:text-white':
                          isActive,
                      }
                    )}
                  >
                    {name}
                  </Link>
                )
              })}
            </div>
          </div>
        </div>
        <div className="space-y-8 mb-12">
          {resources.map(resource => {
            return (
              <ResourceListItem
                key={resource.id}
                title={resource.title}
                description={resource.excerpt}
                category={resource.resourcesCategories.nodes[0]}
                date={resource.date}
                slug={resource.slug}
              />
            )
          })}
        </div>
        <div className="flex justify-center pb-24">
          <Pagination
            page={homeUrl}
            hasNextPage={hasNextPage}
            hasPreviousPage={hasPreviousPage}
            currentPage={currentPage}
            pageCount={pageCount}
            linkProps={{}}
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ResourcesPage($id: String, $offset: Int!, $perPage: Int!) {
    allWpResource(
      filter: {
        resourcesCategories: { nodes: { elemMatch: { id: { eq: $id } } } }
      }
      limit: $perPage
      skip: $offset
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        id
        slug
        title
        link
        date(formatString: "MMMM DD, YYYY")
        excerpt: lwContentTextExcerpt
        resourcesCategories {
          nodes {
            name
            link
            slug
            id
          }
        }
        commentCount
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }

    allWpResourcesCategory {
      nodes {
        id
        name
        slug
        link
      }
    }
  }
`
